<!--
 * @Description: 添加城市弹框
 * @Author: ChenXueLin
 * @Date: 2021-09-10 15:20:48
 * @LastEditTime: 2023-02-15 09:43:09
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="addCityType == 'add' ? '添加城市' : '编辑城市'"
    :visible.sync="addCityVisible"
    width="600px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="custom-dialog"
  >
    <el-form
      ref="cityForm"
      label-width="120px"
      :model="cityForm"
      :rules="cityFormRules"
    >
      <el-form-item label="大区名称" prop="regionalId">
        <e6-vr-select
          v-model="cityForm.regionalId"
          :data="regionalList"
          placeholder="大区名称"
          title="大区名称"
          clearable
          :props="{
            id: 'areaId',
            label: 'areaName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="片区名称" prop="parentId">
        <e6-vr-select
          v-model="cityForm.parentId"
          :data="areaList"
          placeholder="片区名称"
          title="片区名称"
          clearable
          :props="{
            id: 'areaId',
            label: 'areaName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="选择城市/行政区" prop="areaName">
        <city
          @selected="handleCitySelect"
          ref="citySelect"
          :streetDisabled="true"
          :defaultCity.sync="cityForm.areaName"
          :showStreet="false"
        ></city>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import {
  getAreaDown,
  getAreaDetail,
  createCityAdminAreas,
  updateCityAdminAreas
} from "@/api";
export default {
  name: "addCity",
  components: {},
  data() {
    return {
      dialogLoading: false,
      regionalList: [], //大区
      areaList: [], //片区
      cityForm: {
        regionalId: "", //大区id
        parentId: "", //片区id
        areaName: "",
        provinceName: "",
        provinceId: "",
        cityName: "",
        cityId: "",
        countyName: "",
        countyId: ""
      },
      cityFormRules: {
        areaName: [
          {
            required: true,
            message: "请选择省市区",
            trigger: ["blur", "change"]
          }
        ],
        parentId: [
          {
            required: true,
            message: "请选择片区名称",
            trigger: ["blur", "change"]
          }
        ],
        regionalId: [
          {
            required: true,
            message: "请选择大区名称",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: ["addCityVisible", "addCityType", "areaId"],
  mixins: [base],
  computed: {},
  watch: {
    addCityVisible: {
      immediate: true,
      handler(val) {
        console.log(this.cityForm);
        if (val) {
          this.getListIdAndNames();
        }
      }
    },
    "cityForm.regionalId": {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.getListIdAndNames();
          if (oldVal && val != oldVal) {
            this.cityForm.parentId = "";
            this.areaList = [];
          }
        }
        if (!val) {
          this.cityForm.parentId = "";
          this.areaList = [];
        }
      }
    }
  },
  methods: {
    //获取大区和片区列表
    async getListIdAndNames() {
      try {
        let res = await getAreaDown({ parentId: this.cityForm.regionalId });
        if (this.cityForm.regionalId) {
          //片区
          this.areaList = this.getFreezeResponse(res, {
            path: "data"
          });
        } else {
          //大区下拉框
          this.regionalList = this.getFreezeResponse(res, {
            path: "data"
          });
          if (this.addCityType == "edit") {
            this.getAreaDetail();
          }
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取大区详情
    async getAreaDetail() {
      try {
        this.dialogLoading = true;
        let res = await getAreaDetail({ areaId: this.areaId });
        let cityForm = res.data;
        this.cityForm = {
          areaId: cityForm.areaId,
          versionNumber: cityForm.versionNumber,
          regionalId: cityForm.regionalAreaId, //大区id
          parentId: cityForm.sectionAreaId, //片区id
          areaName: cityForm.areaName,
          workEmployeeId: cityForm.workEmployeeId,
          scheduleEmployeeId: cityForm.scheduleEmployeeId,
          provinceName: cityForm.provinceName,
          provinceId: cityForm.provinceId,
          cityName: cityForm.cityName,
          cityId: cityForm.cityId,
          countyName: cityForm.countyName,
          countyId: cityForm.countyId
        };
        console.log(this.cityForm);
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //选中地址
    handleCitySelect(val) {
      if (val) {
        console.log(val, "val---");
        this.cityForm.provinceName = val.province.name;
        this.cityForm.provinceId = val.province.code;
        this.cityForm.cityName = val.city.name;
        this.cityForm.cityId = val.city.code;
        this.cityForm.countyName = val.district.name;
        this.cityForm.countyId = val.district.code;
        this.cityForm.areaName =
          val.province.name + val.city.name + val.district.name;
      }
    },
    confirm() {
      this.$refs.cityForm.validate(valid => {
        if (valid) {
          // if (!this.cityForm.cityId) {
          //   this.$message.warning("请选择到市或者区");
          //   return;
          // }
          if (this.addCityType == "add") {
            this.createCityAdminAreas();
          } else {
            this.updateCityAdminAreas();
          }
        }
      });
    },
    //创建城市请求
    async createCityAdminAreas() {
      try {
        this.dialogLoading = true;
        let res = await createCityAdminAreas(this.cityForm);
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //编辑城市请求
    async updateCityAdminAreas() {
      try {
        this.dialogLoading = true;
        let res = await updateCityAdminAreas(this.cityForm);
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleClose() {
      this.cityForm = {
        regionalId: "", //大区id
        parentId: "", //片区id
        areaName: "",
        provinceName: "",
        provinceId: "",
        cityName: "",
        cityId: "",
        countyName: "",
        countyId: ""
      };
      this.$refs.citySelect.clearCity();
      this.$refs.cityForm.resetFields();
      this.$emit("handleClose", "addCityVisible");
    },
    citySelect(val) {
      console.log(val);
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px 18px 2px 18px;
    .el-input {
      width: 280px;
    }
  }
}
</style>
